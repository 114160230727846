import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Web developper Freelancing`,
    jobType: `Web design & maintenance | Remote`,
    jobDuration: `Jun 2020 - present`,
    timeDuraton: `Part Time`,
    compnayName: "Freelancing",
    jobDescription: `Front-end design & web maintenance of various projects such as online 
    shops built with wordpress & woocomerce using mercadopago as payment provider.
    I also worked on individuals websites, artists blogs, and sustainability activists websites.
    In most of the cases I use wordpress, I also tech the customer by their request, how to manage 
    that website. If the customer does not want to make changes or upload blog content, I rather choose 
    Html5/React JS for making a more compact and faster web.`,
  },
  {
    jobPosition: `Web Designer | Webmaster | & others`,
    jobType: `NGO Tech Coordinator | Remote`,
    jobDuration: `Feb 2017 - Dec 2022`,
    timeDuraton: `Part Time`,
    compnayName: "Proyecto Sub NGO | RelatOceano NGO | Laboratorio Larbim",
    jobDescription: `Front-end design & web maintenance. Organization of virtual congress online.
    Google Cloud (GCP) administrator of various projects, such as a personalized Dropbox using 
    ownCloud. Using GCP features to connect the foundation's image database with an IA from European 
    universities capable of identifying marine species..`,
  },

  {
    jobPosition: `Entrepreneur`,
    jobType: `  `,
    jobDuration: `Jun 2018 - Mar 2020`,
    timeDuraton: `Almost more than full time`,
    compnayName: "Propósito Azul | Sustainable Academy | Turi.eco",
    jobDescription: ` During these years I created different projects while waiting for my final thesis exam at University of Buenos Aires. The Pandemic was not kind to entrepreneurship.`,
  },
];

const educatonContent = [
  {
    passingYear: "May 2022 - present",
    degreeTitle: "MSc. in Blockchain & Cryptocurrencies",
    instituteName: "University Of Nicosia",
  },
  {
    passingYear: "Feb 2021 - May 2022",
    degreeTitle: "MSc. in Sustainable Management",
    instituteName: "University of Salamcanca & Fondo Verde Latinoamerica",
  },
  {
    passingYear: "Aug 2015 - Apr 2018",
    degreeTitle: "Data Science Specialization ",
    instituteName: "Johns Hopkins University | Coursera.org",
  },
  {
    passingYear: "Jan 2013 - Jul 2016",
    degreeTitle: "Advanced Diving Instructor ",
    instituteName: "Scuba Schools International",
  },
  {
    passingYear: "Sept 2011 - Mar 2019",
    degreeTitle: "Degree in biological sciences ",
    instituteName: "University of Buenos Aires",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          <div>
            <h6>This is what kept me busy in the last 5 years...</h6>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
              // End resume-row
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
