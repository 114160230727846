import React from "react";

const ServiceContent = [
  {
    icon: "icon-laptop",
    title: "Web Development",
    descriptions: `I make beautiful interactive and responsive websites using React. I have worked with WordPress a lot and still use it if the customer requires it.  I recently discovered that I love to make WooCommerce shops and teach the owners how to use it and maximize their sales and business efficiency. `,
  },
  {
    icon: "icon-lightbulb",
    title: "Smart Contracts",
    descriptions: `I write useful and innovative smart contracts in solidity. I can deploy them to the main Ethereum network or a layer two solution such as the Polygon network, Arbitrum or Optimist. Furthermore, I can also make beautiful front end web designs to interact seamlessly with them.`,
  },
  {
    icon: "icon-globe",
    title: "Bitcoin & lightning",
    descriptions: `I can mount a Bitcoin node and Lightening Network node. I can also teach and assist others how to do it. Likewise, I can program simple operations in the non Turing complete Bitcoin script programming language. I am currently exploring programming capabilities of the Lightening Network.`,
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
