import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "team-1",
      desc: ` JP is a boss, he has a brilliant mind, when I 
      met him I told myself that I had to find a way to exploit 
      his talent. I made him a partner in one of my ventures. If you do not hire him, you are clearly missing 
      out on a unique opportunity.`,
      reviewerName: "Matias Cali",
      designation: "Entrepreneur & Business owner",
    },
    {
      imageName: "team-2",
      desc: ` "JP, thank you a thousand times for always being 
      attentive and ready with RelatO, I would really die if we 
      didn't have you on the team! I hope to be able to hire you 
      all the times we need you in the future and that you can 
      always accompany us!"`,
      reviewerName: "Yolanda Sánchez",
      designation: "Oceanographer & Co-founder @Relatoceano",
    },
    {
      imageName: "team-3",
      desc: ` It is difficult being alone to carry out all the tasks 
      of my business. I am grateful to have trusted JP and made the 
      decision to become the owner of my own website and online 
      business. JP is very patient with me and is very attentive 
      to everything I always ask of him. Thank you so much JP!`,
      reviewerName: "Naty L.",
      designation: "Products Designer & Business owner",
    },
    {
      imageName: "team-4",
      desc: `  When things are going well, anyone can do the job. When things 
      go wrong, the first person I call is JP. He is outstanding. And 
      always, super clear. `,
      reviewerName: "Martín Brogger",
      designation: "Associate Researcher CONICET",
    },
    {
      imageName: "team-5",
      desc: ` Actually, I really hope that JP doesn't find a job as 
      a full-time programmer. I want him to have lots of free time... So I can 
      still convince him to work with me in my projects with NGOs.`,
      reviewerName: "Gonzalo Bravo",
      designation: "NGO Coordinator & Marine Biologist",
    },
    {
      imageName: "team-6",
      desc: ` I met him when he was still giving scuba-diving classes and 
      doing internships in marine biology. I was just opening my 
      diving school and JP suggested he could build me a website. 
      At first, I thought: a marine biologist making a website?... The truth 
      is that he surprised me and everyone here. Nobody thought that 
      JP was going to make such a beautiful website. He exceeded all 
      expectations. `,
      reviewerName: "Augusto de Camillis",
      designation: "AOWD Instructor & Business owner",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <div className="avatar">
                <img
                  src={`/img/testimonial/${val.imageName}.jpg`}
                  alt="review comments"
                ></img>
              </div>
              <div className="media-body">
                <p>{val.desc}</p>
                <h6>{val.reviewerName}</h6>
                <span>{val.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
