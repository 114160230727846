import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { FiLink } from "react-icons/fi";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const tabList = [
  "All 👀",
  "Web3🦇🔊",
  "₿itcoin & LN⚡",
  "Course Projects👩‍🏫🎓",
  "Websites & WebApps🌎🖥️",
];
const tabListContent = [
  {
    porftoliItems: [
      {
        img: "/img/portfolio/400x550Proyectosub.jpg",
        width: 400,
        height: 550,
        title: "Proyecto Sub",
        subTitle: "Science education NGO. Wordpress Divi website ",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://www.proyectosub.org.ar/",
      },

      {
        img: "/img/portfolio/larbim.jpg",
        width: 400,
        height: 700,
        title: "Laboratorio Larbim",
        subTitle: "Scientific research laboratory. Wordpress Divi website",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://www.larbim.com.ar/",
      },

      {
        img: "/img/portfolio/beaglebuceos.jpg",
        width: 400,
        height: 700,
        title: "Beagle Buceos",
        subTitle: "Diving School. Wordpress Divi website",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://beaglebuceos.com/",
      },
      {
        img: "/img/portfolio/400x550CryptoExchange.jpg",
        width: 400,
        height: 550,
        title: "Coin Exchange with free money",
        subTitle:
          "Course Project worked at Moralis Academy. Made with React JS.",
        alterText: "Course Project worked at Moralis Academy",
        portfolioLink:
          "https://jplaclau.github.io/react-web-development-101v2/",
      },
      {
        img: "/img/portfolio/400x400defensadelarboladovicentelopez.jpg",
        width: 400,
        height: 400,
        title: "Save our trees Galery",
        subTitle:
          "Made with React JS. There has been some mismanagement regarding trees in my neighborhood. This is just a gallery with photos from activists of Vicente López.",
        alterText: "Defensa del arbolado Vicente López",
        portfolioLink: "www.defensadelarboladovicentelopez.com",
      },
      {
        img: "/img/portfolio/400x550WakeUPContract.png",
        width: 400,
        height: 400,
        title: "A bet against oneself to wake up early",
        subTitle: "",
        alterText: "Solidity + hardhat ",
        portfolioLink: "https://github.com/JPLACLAU/WakeUpContract",
      },
      {
        img: "/img/portfolio/400x700HungerGames.png",
        width: 400,
        height: 700,
        title: "Hunger Games",
        subTitle: "A contest against your friends to lose weight",
        alterText: "Solidity + hardhat + JS ",
        portfolioLink: "https://github.com/JPLACLAU/Hunger-Games",
      },
      {
        img: "/img/portfolio/400x550quantify.png",
        width: 400,
        height: 550,
        title: "Quantify your year webApp",
        subTitle:
          "Made with React JS & Watson@Linux. An Open source adaptation to note the time I spend coding & exercising. ",
        alterText: "JPL's coding hours",
        portfolioLink: "https://jplaclau.github.io/quantify-your-year-watson",
      },
      {
        img: "/img/portfolio/400x400bartips.png",
        width: 400,
        height: 400,
        title: "Bar tips",
        subTitle: "Smart Contract made for web3 tipping in bars",
        alterText: "Solidity + JS  ",
        portfolioLink: "https://github.com/JPLACLAU/BarTip",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "/img/portfolio/400x400bartips.png",
        width: 400,
        height: 400,
        title: "Bar tips",
        subTitle: "Smart Contract made for web3 tipping in bars",
        alterText: "Solidity + JS  ",
        portfolioLink: "https://github.com/JPLACLAU/BarTip",
      },
      {
        img: "/img/portfolio/comingsoon.jpg",
        width: 400,
        height: 700,
        title: "Prize 4 Trash",
        subTitle: "Earn for picking up trash in the environment",
        alterText: "Solidity + hardhat + JS + React ",
        portfolioLink: "https://github.com/JPLACLAU",
      },
      {
        img: "/img/portfolio/400x700HungerGames.png",
        width: 400,
        height: 700,
        title: "Hunger Games",
        subTitle: "A contest against your friends to lose weight",
        alterText: "Solidity + hardhat + JS ",
        portfolioLink: "https://github.com/JPLACLAU/Hunger-Games",
      },
      {
        img: "/img/portfolio/400x550WakeUPContract.png",
        width: 400,
        height: 400,
        title: "A bet against oneself to wake up early",
        subTitle: "",
        alterText: "Solidity + hardhat ",
        portfolioLink: "https://github.com/JPLACLAU/WakeUpContract",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "/img/portfolio/400x400.jpg",
        width: 400,
        height: 400,
        title: "Soon...",
        subTitle: "Soon...",
        alterText: "Soon...",
        portfolioLink: "https://github.com/JPLACLAU",
      },

      {
        img: "/img/portfolio/comingsoon.jpg",
        width: 400,
        height: 700,
        title: "Soon...",
        subTitle: "Soon...",
        alterText: "Soon...",
        portfolioLink: "https://github.com/JPLACLAU",
      },
      {
        img: "/img/portfolio/400x550.jpg",
        width: 400,
        height: 550,
        title: "Soon...",
        subTitle: "Soon...",
        alterText: "Soon...",
        portfolioLink: "https://github.com/JPLACLAU",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "/img/portfolio/400x550CryptoExchange.jpg",
        width: 400,
        height: 550,
        title: "Coin Exchange with free money",
        subTitle:
          "Course Project worked at Moralis Academy. Made with React JS.",
        alterText: "Course Project worked at Moralis Academy",
        portfolioLink:
          "https://jplaclau.github.io/react-web-development-101v2/",
      },
      {
        img: "/img/portfolio/comingsoon.jpg",
        width: 400,
        height: 700,
        title: "Coin Exchange simulation",
        subTitle: "Made with React JS",
        alterText: "Course Project worked at Moralis Academy",
        portfolioLink: "https://github.com/JPLACLAU",
      },
      {
        img: "/img/portfolio/400x400.jpg",
        width: 400,
        height: 400,
        title: "Soon...",
        subTitle: "Soon...",
        alterText: "Soon...",
        portfolioLink: "#",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "/img/portfolio/400x550Proyectosub.jpg",
        width: 400,
        height: 550,
        title: "Proyecto Sub",
        subTitle: "Science education NGO. Wordpress Divi website ",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://www.proyectosub.org.ar/",
      },
      {
        img: "/img/portfolio/larbim.jpg",
        width: 400,
        height: 700,
        title: "Laboratorio Larbim",
        subTitle: "Scientific research laboratory. Wordpress Divi website",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://www.larbim.com.ar/",
      },

      {
        img: "/img/portfolio/beaglebuceos.jpg",
        width: 400,
        height: 700,
        title: "Beagle Buceos",
        subTitle: "Diving School. Wordpress Divi website",
        alterText: "Wordpress Divi website",
        portfolioLink: "https://beaglebuceos.com/",
      },
      {
        img: "/img/portfolio/400x400defensadelarboladovicentelopez.jpg",
        width: 400,
        height: 400,
        title: "Save our trees Galery",
        subTitle:
          "Made with React JS. There has been some mismanagement regarding trees in my neighborhood. This is just a gallery with photos from activists of Vicente López.",
        alterText: "Defensa del arbolado Vicente López",
        portfolioLink: "www.defensadelarboladovicentelopez.com",
      },
      {
        img: "/img/portfolio/400x550quantify.png",
        width: 400,
        height: 550,
        title: "Quantify your year webApp",
        subTitle:
          "Made with React JS & Watson@Linux. An Open source adaptation to note the time I spend coding & exercising. ",
        alterText: "JPL's coding hours",
        portfolioLink: "https://jplaclau.github.io/quantify-your-year-watson",
      },
    ],
  },
];

const Portfolio = () => {
  return (
    <div className="portfolio-filter-01">
      <Tabs>
        <TabList className="filter d-flex flex-wrap justify-content-start">
          {tabList.map((val, i) => (
            <Tab key={i}>{val}</Tab>
          ))}
        </TabList>
        {/* End tablist */}
        <Gallery>
          {tabListContent.map((tabContent, i) => (
            <TabPanel key={i}>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {tabContent.porftoliItems.map((val, i) => (
                    <div className="portfolio-box-01" key={i}>
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a
                              href={val.portfolioLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <Item
                          original={val.img}
                          thumbnail={val.img}
                          width={val.width}
                          height={val.height}
                        >
                          {({ ref, open }) => (
                            <div className="gallery-link">
                              <img
                                src={val.img}
                                alt="Childhood"
                                role="button"
                                ref={ref}
                                onClick={open}
                              />
                            </div>
                          )}
                        </Item>

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>
          ))}
        </Gallery>
        {/* End tabpanel */}
      </Tabs>
    </div>
  );
};

export default Portfolio;
